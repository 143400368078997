/* Footer.css */
/* Correction des styles du footer */
/* Ajout d'un style explicite pour le footer */
/* Correction pour rendre le footer non fixe */
/* Renforcement des styles du footer */
/* Ajustement pour que le footer reste toujours visible */
/* Correction pour éviter que le footer recouvre le contenu */
footer {
  position: fixed; /* Fixe le footer en bas de la fenêtre */
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

/* Add padding to prevent content overlap */
.content {
  padding-bottom: 60px; /* Ajoute un espace pour éviter que le contenu soit masqué par le footer */
}

/* Correction pour les liens dans le footer */
footer a {
  color: inherit !important; /* Hérite de la couleur du texte parent */
  text-decoration: none !important; /* Supprime le soulignement */
}

footer a:hover {
  text-decoration: underline !important; /* Ajoute un soulignement au survol */
}