/* Consolidated global styles */
:root {
  --primary-bg-color: #f9f9f9; /* Couleur de fond principale */
  --primary-text-color: white;
  --navbar-height: 110px;
  --footer-height: 60px;
  --padding-small: 10px;
  --padding-medium: 15px;
  --padding-large: 20px;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  background-image: url('./assets/hero-bg.jpg');
  background-size: cover; /* Ensure the image covers the entire page */
  background-repeat: no-repeat; /* Prevent repetition */
  background-attachment: fixed; /* Keep the image fixed during scrolling */
  background-position: center; /* Center the image */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  display: flex;
  flex-direction: column;
}

/* Remove conflicting styles for body */
body {
  background-color: unset;
  background-image: unset;
  background-size: unset;
  background-repeat: unset;
  background-attachment: unset;
  background-position: unset;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  text-align: center;
  padding: var(--padding-small) 0;
  z-index: 1000;
}

/* Ajustement global pour éviter que le footer recouvre le contenu */
.content {
  flex: 1;
  padding-top: var(--navbar-height);
  padding-bottom: var(--footer-height); /* Ajoute un espace pour éviter que le contenu soit masqué par le footer */
  overflow-y: auto; /* Permet le défilement uniquement dans la partie centrale */
}

/* Ensure footer is consistent across all components */
.footer {
  position: fixed; /* Fixe le footer en bas de la fenêtre */
  bottom: 0;
  width: 100%;
  height: var(--footer-height);
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}